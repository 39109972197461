import Vue from 'vue'

import axios from 'axios'
import querystring from 'querystring'

const base = process.env.NODE_ENV === 'developmenst' ? 'http://localhost:5000' : 'https://kysapi.prosparency.com'

const get = async function (endpoint, $auth, options) {
  //
  // let query = querystring.stringify(params)
  // if (query.length > 0) {
  //   endpoint += `?${query}`
  // }

  console.log(`%capiFunction%c ${endpoint}`, 'background:green;color:white', 'color:black')
  let url = `${base}/${endpoint}`

  const accessToken = await $auth.getAPIToken()

  if( !options ){
    options = {}
  }

  options = Object.assign(options, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })


  return axios.get(url, options)
}

const post = async function (endpoint, data, $auth ) {
  let url = `${base}/${endpoint}`
  console.log(`%capiFunction%c ${endpoint}`, 'background:green;color:white', 'color:black')

  const accessToken = await $auth.getAPIToken()

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.post(url, data, options)
}

const api =  {
  get,
  post
}

Vue.prototype.$kysapi = api

export const kysapi = api
